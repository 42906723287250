import { Grid, Paper } from '@material-ui/core'
import { useGetBenefitPlanById } from 'api/benefit-elections/queries/use-get-benefit-plan-by-id'
import { FormRichText } from 'components/form'
import Text from 'components/text/text'
import { UseFormReturnType } from 'hooks/use-form'
import React from 'react'
import { useParams } from 'react-router-dom'
import RatesFormHeader from './rates-form-header'
import { RatesFormType } from './types'

interface RatesHighlightsFormProps {
	form: UseFormReturnType<RatesFormType>
}

const RatesHighlightsForm: React.FC<RatesHighlightsFormProps> = ({ form }: RatesHighlightsFormProps) => {
	const { id, benefitPlanId } = useParams<{
		id: string
		benefitPlanId: string
	}>()
	const { data: benefitPlan } = useGetBenefitPlanById({ benefitPlanId: benefitPlanId! })
	const { handleSubmit, isSubmitting } = form

	const uniquePlanCoverageCodes = [...new Set(form.values.planCoverages.map((item) => item.planCoverageCode))]
	const uniquePlanCoverages = uniquePlanCoverageCodes.map((code) => {
		return form.values.planCoverages.find((item) => item.planCoverageCode === code)
	})

	const handleEditorChange = (value, code): void => {
		const newCoverages = form.values.planCoverages.map((x) => {
			if (x.planCoverageCode === code) x.planCoverageContent = value

			return x
		})

		form.setFields({ target: { name: `planCoverages`, value: newCoverages } })
	}

	return (
		<Paper elevation={3} className='paper-spacing'>
			<Grid container alignItems='center' spacing={3}>
				<RatesFormHeader
					isSubmitting={isSubmitting}
					handleSubmit={handleSubmit}
					benefitPlan={benefitPlan}
					orgId={id!}
				/>
				<Grid item container spacing={3} alignItems='center'>
					{uniquePlanCoverages.map((planCoverage, index) => {
						const planCoverageValue =
							(planCoverage &&
								form.values.planCoverages.find((pc) => pc.planCoverageCode === planCoverage.planCoverageCode)
									?.planCoverageContent) ||
							null

						return (
							<Grid item xs={12} key={index}>
								<Grid item xs={12} lg={6}>
									<Text
										variant='h6'
										color='textPrimary'
									>{`${planCoverage?.planCoverageLabel} - ${planCoverage?.planCoverageCode}`}</Text>
									<FormRichText
										id={(planCoverage?.id ?? index).toString()}
										value={planCoverageValue}
										onChange={(e) => handleEditorChange(e, planCoverage?.planCoverageCode)}
										useSmallTextLabel
									/>
								</Grid>
							</Grid>
						)
					})}
				</Grid>
			</Grid>
		</Paper>
	)
}

export default RatesHighlightsForm
