interface CustomShadows {
	z1: string
	z4: string
	z8: string
	z12: string
	z16: string
	z20: string
	z24: string
	//
	primary: string
	secondary: string
	info: string
	success: string
	warning: string
	error: string
	//
	card: string
	dialog: string
	dropdown: string
}

declare module '@mui/material/styles' {
	interface Theme {
		customShadows: CustomShadows
	}
	interface ThemeOptions {
		customShadows?: CustomShadows
	}
	interface CssVarsTheme {
		customShadows: CustomShadows
	}
	interface CssVarsThemeOptions {
		customShadows?: CustomShadows
	}
}

// ----------------------------------------------------------------------

// const c = {
// 	primary: 'var(--mui-palette-primary-mainChannel)',
// 	secondary: 'var(--mui-palette-secondary-mainChannel)',
// 	info: 'var(--mui-palette-info-mainChannel)',
// 	success: 'var(--mui-palette-success-mainChannel)',
// 	warning: 'var(--mui-palette-warning-mainChannel)',
// 	error: 'var(--mui-palette-error-mainChannel)',
// }

export function customShadows() {
	const shadowRGB = '158 158 158'

	return {
		z1: `0 1px 2px 0 rgba(${shadowRGB} / 0.04)`,
		z4: `-4px 4px 12px 0 rgba(${shadowRGB} / 0.08)`,
		z8: `-8px 8px 24px -4px rgba(${shadowRGB} / 0.08)`,
		z12: `-12px 12px 36px -4px rgba(${shadowRGB} / 0.12)`,
		z16: `-16px 16px 48px -8px rgba(${shadowRGB} / 0.16)`,
		z20: `-20px 20px 60px -8px rgba(${shadowRGB} / 0.2)`,
		z24: `-24px 24px 72px -8px rgba(${shadowRGB} / 0.24)`,
		//

		info: `0 8px 16px 0 rgba(var(--mui-palette-info-mainChannel) / 0.24)`,
		success: `0 8px 16px 0 rgba(var(--mui-palette-success-mainChannel) / 0.24)`,
		warning: `0 8px 16px 0 rgba(var(--mui-palette-warning-mainChannel) / 0.24)`,
		error: `0 8px 16px 0 rgba(var(--mui-palette-error-mainChannel) / 0.24)`,

		//
		card: `3px 6px 13px 0px rgba(${shadowRGB}, 0.03), 11px 22px 25px 0px rgba(${shadowRGB} / 0.03),\
		 24px 50px 33px 0px rgba(${shadowRGB} / 0.02), 43px 88px 39px 0px rgba(${shadowRGB} / 0),\
		 67px 138px 43px 0px rgba(${shadowRGB} / 0)`,
		// card: `0 0 2px 0 ${getColor(black, 0.2)}, 0 12px 24px -4px ${getColor(black, 0.12)}`,
		dialog: `3px 6px 13px 0px rgba(${shadowRGB} / 0.03), 11px 22px 25px 0px rgba(${shadowRGB} / 0.03),\
		 24px 50px 33px 0px rgba(${shadowRGB} / 0.02), 43px 88px 39px 0px rgba(${shadowRGB} / 0),\
		 67px 138px 43px 0px rgba(${shadowRGB} / 0)`,
		dropdown: `0 0 2px 0 rgba(${shadowRGB} / 0.24), -20px 20px 40px -4px rgba(${shadowRGB} / 0.24)`,
	}
}
