import {
	AccessibilityHelp,
	AutoLink,
	Autoformat,
	Autosave,
	BalloonToolbar,
	BlockToolbar,
	EditorConfig,
	Essentials,
	FindAndReplace,
	GeneralHtmlSupport,
	Link,
	List,
	Paragraph,
	PasteFromOffice,
	RemoveFormat,
	SelectAll,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	TextTransformation,
	Undo,
} from 'ckeditor5'
import { DisableImagePastePlugin } from '../custom-plugins/disable-image-paste'
import { CkeditorSimpleUploadConfig } from './types'

export const getTextAndBulletsConfig = ({
	uploadConfig,
	licenseKey,
}: {
	uploadConfig: CkeditorSimpleUploadConfig | undefined
	licenseKey: string
}): EditorConfig => ({
	licenseKey,
	toolbar: {
		items: ['undo', 'redo', '|', 'link', '|', 'bulletedList'],
		shouldNotGroupWhenFull: false,
	},
	plugins: [
		AccessibilityHelp,
		Autoformat,
		AutoLink,
		Autosave,
		BalloonToolbar,
		BlockToolbar,
		Essentials,
		FindAndReplace,
		GeneralHtmlSupport,
		Link,
		List,
		Paragraph,
		PasteFromOffice,
		RemoveFormat,
		SelectAll,
		SpecialCharacters,
		SpecialCharactersArrows,
		SpecialCharactersCurrency,
		SpecialCharactersEssentials,
		SpecialCharactersLatin,
		SpecialCharactersMathematical,
		SpecialCharactersText,
		TextTransformation,
		Undo,
		DisableImagePastePlugin,
	],
	balloonToolbar: ['link', '|', 'bulletedList'],
	blockToolbar: ['link', '|', 'bulletedList'],
	htmlSupport: {
		allow: [
			{
				name: /^.*$/,
				styles: true,
				attributes: true,
				classes: true,
			},
		],
	},
	link: {
		addTargetToExternalLinks: true,
		defaultProtocol: 'https://',
		decorators: {
			toggleDownloadable: {
				mode: 'manual',
				label: 'Downloadable',
				attributes: {
					download: 'file',
				},
			},
		},
	},
	menuBar: {
		isVisible: true,
	},
	placeholder: 'Type or paste your content here!',
	simpleUpload: uploadConfig,
})
