import { AsyncProviderConfig, asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { StrictMode } from 'react'
import UrlBuilder from 'services/util/url-builder'
import { Environments } from 'utils/env'
import App from './app'
import { SiteEnvironment } from './types'

export async function setupApp(site: SiteEnvironment, root) {
	new UrlBuilder().setHost(site.env)

	const LDProvider = await asyncWithLDProvider(await configureLaunchDarkly(site.env as Environments))

	root.render(
		<StrictMode>
			<LDProvider>
				<App />
			</LDProvider>
		</StrictMode>,
	)
}

/**
 * Setup the LaunchDarkly contexts for the user and organization
 */
async function configureLaunchDarkly(env: Environments): Promise<AsyncProviderConfig> {
	let version = 'unknown'
	try {
		const response = await fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
		const { version: metaVersion } = await response.json()
		version = metaVersion
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to fetch version from meta.json:', error)
	}
	const ldClientId = getLdClientIdForEnv(env)

	return {
		clientSideID: ldClientId,
		context: {
			kind: 'multi',
			organization: {
				key: 'ADMIN',
				name: 'ADMIN',
			},
			user: {
				key: 'ADMIN',
				organization: 'ADMIN',
			},
		},
		options: {
			application: {
				id: 'cs-admin-ui',
				version: version,
			},
			eventCapacity: 500,
		},
		reactOptions: { useCamelCaseFlagKeys: false },
	}
}

/**
 * From the environment, get the correct LD client id configured in the .env files
 */
function getLdClientIdForEnv(env: Environments) {
	const ldClientIds = {
		[Environments.DEV]: import.meta.env.VITE_LD_ID_DEV,
		[Environments.INT]: import.meta.env.VITE_LD_ID_INT,
		[Environments.UAT]: import.meta.env.VITE_LD_ID_UAT,
	}

	const ldClientId = ldClientIds[env] || import.meta.env.VITE_LD_ID

	return ldClientId
}
