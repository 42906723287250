import { FileLoader, FileRepository, Plugin, logWarning } from 'ckeditor5'
import { CkeditorSimpleUploadConfig } from 'components/ckeditor/ckeditor-configurations/types'

export class SimpleFileUploadAdapter extends Plugin {
	static get requires(): [typeof FileRepository] {
		return [FileRepository]
	}

	static get pluginName() {
		return 'SimpleFileUploadAdapter'
	}

	init(): void {
		const options = this.editor.config.get('simpleUpload') as CkeditorSimpleUploadConfig | undefined

		if (!options) {
			return
		}

		if (!options.uploadUrl) {
			// eslint-disable-next-line no-console
			console.warn(
				logWarning(
					'simple-upload-adapter-missing-uploadUrl: Missing the "uploadUrl" property in the ' +
						'"simpleUpload" editor configuration.',
				),
			)

			return
		}

		this.editor.plugins.get(FileRepository).createUploadAdapter = (loader): FileUploadAdapter => {
			return new FileUploadAdapter(loader, options)
		}
	}
}

class FileUploadAdapter {
	private xhr: XMLHttpRequest | null = null

	constructor(
		private loader: FileLoader,
		private options: CkeditorSimpleUploadConfig,
	) {}

	upload(): Promise<{ resourceUrl: string }> {
		return this.loader.file.then(
			(file) =>
				new Promise((resolve, reject) => {
					if (!file) {
						reject('File not found')

						return
					}

					this._initRequest()

					this._initListeners(resolve, reject, file)
					this._sendRequest(file)
				}),
		)
	}

	abort(): void {
		if (this.xhr) {
			this.xhr.abort()
		}
	}

	private _initRequest(): void {
		const xhr = (this.xhr = new XMLHttpRequest())

		xhr.open('POST', this.options.uploadUrl, true)
		xhr.responseType = 'json'
	}

	private _initListeners(
		resolve: (value: { resourceUrl: string }) => void,
		reject: (reason?: any) => void,
		file: File,
	): void {
		const xhr = this.xhr as XMLHttpRequest
		const loader = this.loader
		const genericErrorText = `Couldn't upload file: ${file.name}.`

		xhr.addEventListener('error', () => reject(genericErrorText))
		xhr.addEventListener('abort', () => reject())
		xhr.addEventListener('load', () => {
			const response = xhr.response as { url: string; error?: { message: string } } | null

			if (!response || response.error) {
				return reject(response && response.error ? response.error.message : genericErrorText)
			}

			resolve({
				resourceUrl: response.url,
			})
		})

		if (xhr.upload) {
			xhr.upload.addEventListener('progress', (evt) => {
				if (evt.lengthComputable) {
					loader.uploadTotal = evt.total
					loader.uploaded = evt.loaded
				}
			})
		}
	}

	private _sendRequest(file: File): void {
		const xhr = this.xhr as XMLHttpRequest
		const { headers = {}, withCredentials = false } = this.options

		Object.entries(headers).forEach(([headerName, headerValue]) => {
			xhr.setRequestHeader(headerName, headerValue as string)
		})

		xhr.withCredentials = withCredentials

		const data = new FormData()
		data.append('upload', file)

		xhr.send(data)
	}
}
