import { Plugin } from 'ckeditor5'
import { FileUploadEditing } from './file-upload-editing'
import { FileUploadUI } from './file-upload-ui'

export class FileUpload extends Plugin {
	static get requires() {
		return [FileUploadEditing, FileUploadUI] as const
	}

	static get pluginName() {
		return 'FileUpload' as const
	}
}
