import { Command, type Editor, FileRepository, type Model, type Writer } from 'ckeditor5'
import { insertFileLink } from './utils'

type ExecuteOptions = {
	file: File | File[]
}

export class FileUploadCommand extends Command {
	declare readonly editor: Editor

	/**
	 * @inheritDoc
	 */
	refresh(): void {
		this.isEnabled = true
	}

	/**
	 * Executes the command.
	 *
	 * @fires execute
	 * @param options Options for the executed command.
	 */
	execute(options: ExecuteOptions): void {
		const { editor } = this
		const model = editor.model

		const fileRepository = editor.plugins.get(FileRepository)

		model.change((writer) => {
			const filesToUpload = Array.isArray(options.file) ? options.file : [options.file]
			for (const file of filesToUpload) {
				uploadFile(writer, model, fileRepository, file)
			}
		})
	}
}

/**
 * Handles uploading single file.
 */
function uploadFile(writer: Writer, model: Model, fileRepository: FileRepository, file: File): void {
	const loader = fileRepository.createLoader(file)

	// Do not throw when upload adapter is not set. FileRepository will log an error anyway.
	if (!loader) {
		return
	}

	insertFileLink(writer, model, { linkHref: '', uploadId: loader.id }, file)
}
