/* eslint-disable max-len */
import { findOptimalInsertionRange } from 'ckeditor5'

/**
 * Creates a regular expression used to test for files.
 */
export function createFileTypeRegExp(types: Record<string, string>) {
	// Sanitize the MIME type name which may include: "+", "-" or ".".
	const regExpSafeNames = Object.values(types).map((type) => type.replace('+', '\\+'))

	return new RegExp(`^application\\/(${regExpSafeNames.join('|')})$`)
}

/**
 * Fetches a local file and converts it to a File instance.
 */
export function fetchLocalFile(link: any): Promise<File> {
	return new Promise((resolve, reject) => {
		const linkHref = link.getAttribute('href') as string | null

		if (!linkHref) {
			reject(new Error('Link href is missing'))

			return
		}

		fetch(linkHref)
			.then((resource) => resource.blob())
			.then((blob) => {
				const mimeType = getFileMimeType(blob, linkHref)
				const ext = mimeType.replace('file/', '')
				const filename = `file.${ext}`
				const file = createFileFromBlob(blob, filename, mimeType)

				file ? resolve(file) : reject(new Error('Failed to create file'))
			})
			.catch(reject)
	})
}

/**
 * Checks if the given node is a local file.
 * @param {ckeditor5.Node} node
 */
export function isLocalFile(node: any): boolean {
	if (!node.is('element', 'a') || !node.getAttribute('href')) {
		return false
	}

	return Boolean(node.getAttribute('href'))
}

function getFileMimeType(blob: Blob, src: string): string {
	if (blob.type) {
		return blob.type
	}

	const match = src.match(/data:(image\/\w+);base64/)
	if (match) {
		return match[1].toLowerCase()
	}

	throw new Error('Could not retrieve mime type for file.')
}

function createFileFromBlob(blob: Blob, filename: string, mimeType: string): File | null {
	try {
		return new File([blob], filename, { type: mimeType })
	} catch (err) {
		// Edge does not support `File` constructor ATM, see https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/9551546/.
		// However, the `File` function is present (so cannot be checked with `!window.File` or `typeof File === 'function'`), but
		// calling it with `new File( ... )` throws an error. This try-catch prevents that. Also when the function will
		// be implemented correctly in Edge the code will start working without any changes (see #247).

		return null
	}
}

export function insertFileLink(writer: any, model: any, attributes: Record<string, unknown> = {}, file: File): void {
	const selection = model.document.selection
	const insertAtSelection = findOptimalInsertionRange(selection, model)

	const linkedText = writer.createText(file.name, attributes)
	model.insertContent(linkedText, insertAtSelection)

	if (linkedText.parent) {
		writer.setSelection(linkedText, 'on')
	}
}
