import {
	Clipboard,
	ClipboardContentInsertionData,
	Editor,
	FileRepository,
	Item,
	Notification,
	Plugin,
	UpcastWriter,
} from 'ckeditor5'
import { FileUploadCommand } from './file-upload-command'
import { createFileTypeRegExp, fetchLocalFile, isLocalFile } from './utils'

/**
 * The editing part of the file upload feature. It registers the `'fileUpload'` command.
 *
 * @extends module:core/plugin~Plugin
 */
export class FileUploadEditing extends Plugin {
	/**
	 * @inheritDoc
	 */
	constructor(editor) {
		super(editor)
	}

	/**
	 * @inheritDoc
	 */
	static get requires() {
		return [FileRepository, Notification, Clipboard]
	}

	static get pluginName() {
		return 'FileUploadEditing'
	}

	/**
	 * @inheritDoc
	 */
	init() {
		const editor = this.editor
		const doc = editor.model.document
		const schema = editor.model.schema
		const conversion = editor.conversion
		const fileRepository = editor.plugins.get(FileRepository)

		const fileTypes = createFileTypeRegExp(
			(editor.config.get('simpleFileUpload.fileTypes') as Record<string, string>) ?? {},
		)

		// Setup schema to allow uploadId and uploadStatus for files.
		schema.extend('$text', {
			allowAttributes: ['uploadId', 'uploadStatus'],
		})

		// Register fileUpload command.
		editor.commands.add('fileUpload', new FileUploadCommand(editor))

		// Register upcast converter for uploadId.
		conversion.for('upcast').attributeToAttribute({
			view: {
				name: 'a',
				key: 'uploadId',
			},
			model: 'uploadId',
		})

		this.listenTo(editor.editing.view.document, 'clipboardInput', (evt, data: ClipboardContentInsertionData) => {
			// Skip if non empty HTML data is included.
			// https://github.com/ckeditor/ckeditor5-upload/issues/68
			if (isHtmlIncluded(data.dataTransfer)) {
				return
			}

			const files = Array.from(data.dataTransfer.files).filter((file) => {
				if (!file) {
					return false
				}

				return fileTypes.test(file.type)
			})

			const ranges = data.targetRanges?.map((viewRange) => editor.editing.mapper.toModelRange(viewRange)) ?? []

			editor.model.change((writer) => {
				// Set selection to paste target.
				writer.setSelection(ranges)

				if (files.length) {
					evt.stop()

					// Upload files after the selection has changed in order to ensure the command's state is refreshed.
					editor.model.enqueueChange(() => {
						editor.execute('fileUpload', { file: files })
					})
				}
			})
		})

		this.listenTo(editor.plugins.get(Clipboard), 'inputTransformation', (evt, data) => {
			const fetchableFiles = Array.from(editor.editing.view.createRangeIn(data.content))
				.filter((value) => isLocalFile(value.item) && !(value.item as any).getAttribute('uploadProcessed'))
				.map((value) => {
					return { promise: fetchLocalFile(value.item), fileElement: value.item }
				})

			if (!fetchableFiles.length) {
				return
			}

			// @ts-expect-error testing
			const writer = new UpcastWriter()

			for (const fetchableFile of fetchableFiles) {
				writer.setAttribute('uploadProcessed', true, fetchableFile.fileElement as unknown as any)

				const loader = fileRepository.createLoader(fetchableFile.promise)

				if (loader) {
					writer.setAttribute('href', '', fetchableFile.fileElement as unknown as any)
					writer.setAttribute('uploadId', loader.id, fetchableFile.fileElement as unknown as any)
				}
			}
		})

		// Prevents from the browser redirecting to the dropped file.
		editor.editing.view.document.on('dragover', (evt, data) => {
			data.preventDefault()
		})

		// Upload placeholder files that appeared in the model.
		doc.on('change', () => {
			const changes = doc.differ.getChanges({ includeChangesInGraveyard: true })
			for (const entry of changes) {
				if (entry.type === 'insert') {
					const item = entry.position.nodeAfter
					if (item) {
						const isInGraveyard = entry.position.root.rootName === '$graveyard'
						for (const file of getFileLinksFromChangeItem(editor, item)) {
							// Check if the file element still has upload id.
							const uploadId = file.getAttribute('uploadId')
							if (!uploadId) {
								continue
							}

							// Check if the file is loaded on this client.
							const loader = fileRepository.loaders.get(uploadId as string)

							if (!loader) {
								continue
							}

							if (isInGraveyard) {
								// If the file was inserted to the graveyard - abort the loading process.
								loader.abort()
							} else if (loader.status === 'idle') {
								// If the file was inserted into content and has not been loaded yet, start loading it.
								this._readAndUpload(loader, file)
							}
						}
					}
				}
			}
		})
	}

	/**
	 * Reads and uploads a file.
	 *
	 * @protected
	 * @param {module:upload/filerepository~FileLoader} loader
	 * @param {module:engine/model/element~Element} fileElement
	 * @returns {Promise}
	 */
	_readAndUpload(loader, fileElement) {
		const editor = this.editor
		const model = editor.model
		const t = editor.locale.t
		const fileRepository = editor.plugins.get(FileRepository)
		const notification = editor.plugins.get(Notification)

		model.enqueueChange((writer) => {
			writer.setAttribute('uploadStatus', 'reading', fileElement as unknown as any)
		})

		return loader
			.read()
			.then(() => {
				const promise = loader.upload()

				model.enqueueChange((writer) => {
					writer.setAttribute('uploadStatus', 'uploading', fileElement)
				})

				return promise
			})
			.then((data) => {
				model.enqueueChange((writer) => {
					writer.setAttributes({ uploadStatus: 'complete', linkHref: data.resourceUrl }, fileElement)
				})

				clean()
			})
			.catch((error) => {
				// If status is not 'error' nor 'aborted' - throw error because it means that something else went wrong,
				// it might be generic error and it would be real pain to find what is going on.
				if (loader.status !== 'error' && loader.status !== 'aborted') {
					throw error
				}

				// Might be 'aborted'.
				if (loader.status === 'error' && error) {
					notification.showWarning(error, {
						title: t('Upload failed'),
						namespace: 'upload',
					})
				}

				clean()

				// Permanently remove file from insertion batch.
				model.enqueueChange((writer) => {
					writer.remove(fileElement)
				})
			})

		function clean() {
			model.enqueueChange((writer) => {
				writer.removeAttribute('uploadId', fileElement as unknown as any)
				writer.removeAttribute('uploadStatus', fileElement as unknown as any)
			})

			fileRepository.destroyLoader(loader)
		}
	}
}

// Returns `true` if non-empty `text/html` is included in the data transfer.
//
// @param {module:clipboard/datatransfer~DataTransfer} dataTransfer
// @returns {Boolean}
export function isHtmlIncluded(dataTransfer) {
	return Array.from(dataTransfer.types).includes('text/html') && dataTransfer.getData('text/html') !== ''
}

function getFileLinksFromChangeItem(editor: Editor, item: Item) {
	return Array.from(editor.model.createRangeOn(item))
		.filter((value) => value.item.hasAttribute('linkHref'))
		.map((value) => value.item)
}
