import { cx } from 'class-variance-authority'
import { CKEditorInputConfig, CkeditorInput } from 'components/ckeditor/ckeditor-input'
import Text from 'components/text'
import React from 'react'
import { useAuth } from 'react-oidc-context'
import { match } from 'ts-pattern'
import styles from './form-rich-text.module.css'

interface FormRichTextProps {
	className?: string
	errorMessage?: string
	hasError?: boolean
	id?: string
	label?: string
	onBlur?: any
	onChange: any
	required?: boolean
	value: string | null
	readonly?: boolean
	useSmallTextLabel?: boolean
	entityIdForFileUpload?: string
	isTextOnly?: boolean
	isBulletedOnly?: boolean
	isNoFormattingTextOnly?: boolean
	clearFormatting?: boolean
}

const FormRichText: React.FC<FormRichTextProps> = (props: FormRichTextProps) => {
	const { user } = useAuth()

	const fileTools_requestHeaders: any = {}
	if (props.entityIdForFileUpload) {
		const sessionToken = user?.access_token
		if (sessionToken) {
			fileTools_requestHeaders.Authorization = `Bearer ${sessionToken}`
		}
	}

	// Determines which toolbar to display
	const toolbarContent = () => {
		return match({
			isBulletedOnly: props.isBulletedOnly,
			isTextOnly: props.isTextOnly,
			isNoFormattingTextOnly: props.isNoFormattingTextOnly,
		})
			.with({ isBulletedOnly: true }, () => 'bulletdListOnly')
			.with({ isTextOnly: true }, () => 'formattedTextAndBulletsConfig')
			.with({ isNoFormattingTextOnly: true }, () => 'emptyAndBullets')
			.otherwise(() => 'default')
	}

	return (
		<div className={cx(`form-textfield ${props.className ?? ''}`, styles['container'])} onBlur={props.onBlur}>
			<Text variant={props.useSmallTextLabel ? 'body2' : undefined}>
				{props.label}
				{props.required ? '*' : ''}{' '}
			</Text>
			<CkeditorInput
				preset={toolbarContent() as CKEditorInputConfig['preset']}
				id={props.id ?? ''}
				onChange={props.onChange}
				onBlur={props.onBlur}
				initialData={props.value ?? ''}
				disabled={props.readonly}
				clearFormatting={props.clearFormatting}
			/>
			<div className={`error ${props.hasError ? 'error-show' : 'error-hide'} error-message`}>{props.errorMessage}</div>
		</div>
	)
}

export default FormRichText
