import { Plugin } from 'ckeditor5'

export class DisableImagePastePlugin extends Plugin {
	init() {
		const editor = this.editor

		editor.plugins.get('Clipboard').on('inputTransformation', (evt, data) => {
			const clipboardData = data.dataTransfer.getData('text/html')

			// Check if clipboardData contains an image
			if (clipboardData?.includes('<img')) {
				// Prevent default image pasting
				evt.stop()
			}
		})
	}
}
