import { Link } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Navigate, Outlet, UIMatch, generatePath, useParams } from 'react-router-dom'
import { DynamicBreadcrumb } from './breadcrumbs'
import { routePaths } from './route-paths'
import { CsRoute } from './types'
import { prepareRoutes } from './utils/prepareRoutes'

export const useBenefitProviderRoutes = (): CsRoute[] => {
	const { transformers8737 } = useFlags()

	return [
		{
			path: routePaths.benefitProviders.path,
			handle: {
				crumb: () => <Link href={routePaths.benefitProviders.path}>Benefit Providers</Link>,
			},
			children: [
				{
					index: true,
					lazy: () =>
						import('pages/benefit-providers/benefit-providers').then((module) => ({ Component: module.default })),
				},
				...prepareRoutes(
					[
						{
							lazy: () => import('pages/create-benefit-provider').then((module) => ({ Component: module.default })),
							path: routePaths.benefitProviders.create,
						},
						{
							path: `${routePaths.benefitProviders.edit.root}/*`,
							lazy: () =>
								import('pages/benefit-providers/benefit-providers-layout').then((module) => ({
									Component: module.BenefitProviderLayout,
								})),
							handle: {
								crumb: (data: UIMatch<any, any>) => <DynamicBreadcrumb portalId={data.params.id ?? ''} />,
							},
							children: prepareRoutes(
								[
									{
										path: routePaths.benefitProviders.edit.details.path,
										lazy: () => import('pages/edit-benefit-provider').then((module) => ({ Component: module.default })),
										// name: 'Edit Benefit Provider',
									},
									{
										path: routePaths.benefitProviders.edit.ftpConfigurations.path,
										lazy: () =>
											import('pages/benefit-providers').then((module) => ({
												Component: module.BenefitProviderFtpConfigurations,
											})),
										// name: 'FTP Configurations',
										flag: transformers8737,
									},
									{
										path: routePaths.benefitProviders.edit.externalBankAccounts.path,
										lazy: () =>
											import('pages/benefit-provider-bank-account').then((module) => ({
												Component: module.BenefitProviderBankListing,
											})),
										// name: 'Bank Accounts',
										flag: transformers8737,
									},
									{
										path: routePaths.benefitProviders.edit.settings.path,
										lazy: () =>
											import('pages/benefit-provider-settings').then((module) => ({
												Component: module.ProviderSettingsPage,
											})),
										// name: 'Settings',
									},
									{
										path: `*`,
										element: <RootPath />,
										// name: 'Edit Benefit Provider',
									},
									{
										path: routePaths.benefitProviders.edit.products.path,
										handle: {
											crumb: () => <div>Benefit Products</div>,
										},
										children: [
											{
												index: true,
												element: (
													<>
														<Outlet />
													</>
												),
											},
											...prepareRoutes(
												[
													{
														lazy: () =>
															import('pages/create-benefit-product').then((module) => ({ Component: module.default })),
														// name: 'Create Benefit Product',
														path: routePaths.benefitProviders.edit.products.create.path,
														handle: {
															crumb: () => <div>Create</div>,
														},
													},
													{
														path: routePaths.benefitProviders.edit.products.edit.root,
														handle: {
															crumb: (data) => <DynamicBreadcrumb portalId={data.params.benefitProductId ?? ''} />,
														},
														lazy: () =>
															import('pages/benefit-providers/benefit-products/layouts').then((module) => ({
																Component: module.BenefitProductsLayout,
															})),
														children: [
															{
																path: '*',
																element: <Navigate to={'details'} replace />,
															},
															{
																path: routePaths.benefitProviders.edit.products.edit.details.localTabPath,
																lazy: () =>
																	import('pages/edit-benefit-product').then((module) => ({
																		Component: module.default,
																	})),
															},
															{
																path: routePaths.benefitProviders.edit.products.edit.benefitPlans.localTabPath,
																lazy: () =>
																	import('pages/benefit-product-plans').then((module) => ({
																		Component: module.default,
																	})),
															},
															...prepareRoutes(
																[
																	{
																		path: routePaths.benefitProviders.edit.products.edit.templates.path,
																		handle: {
																			crumb: () => <div>Product Templates</div>,
																		},
																		children: [
																			...prepareRoutes(
																				[
																					{
																						lazy: () =>
																							import('pages/create-product-template/legacy').then((module) => ({
																								Component: module.default,
																							})),
																						path: routePaths.benefitProviders.edit.products.edit.templates.create.legacy
																							.path,
																					},
																					{
																						lazy: () =>
																							import('pages/create-product-template/').then((module) => ({
																								Component: module.default,
																							})),
																						path: routePaths.benefitProviders.edit.products.edit.templates.create
																							.modular.path,
																					},
																					{
																						path: routePaths.benefitProviders.edit.products.edit.templates.edit.legacy
																							.root,
																						handle: {
																							crumb: (data: UIMatch<any, any>) => {
																								return (
																									<DynamicBreadcrumb portalId={data.params.productTemplateId ?? ''} />
																								)
																							},
																						},
																						lazy: () =>
																							import(
																								'pages/benefit-providers/benefit-products/product-templates/layouts'
																							).then((module) => ({
																								Component: module.BenefitProductProductTemplatesLayout,
																							})),
																						children: prepareRoutes(
																							[
																								{
																									path: '*',
																									element: <Navigate to={'details'} replace />,
																								},
																								{
																									path: routePaths.benefitProviders.edit.products.edit.templates.edit
																										.legacy.details.path,
																									lazy: () =>
																										import('pages/edit-product-template/legacy').then((module) => ({
																											Component: module.default,
																										})),
																									handle: {
																										crumb: () => <div>Details</div>,
																									},
																								},
																								{
																									path: routePaths.benefitProviders.edit.products.edit.templates.edit
																										.legacy.marketingContents.path,
																									lazy: () =>
																										import('pages/edit-product-template').then((module) => ({
																											Component: module.MarketingContents,
																										})),
																									handle: {
																										crumb: () => <div>Marketing Contents</div>,
																									},
																								},
																							],
																							{
																								// eslint-disable-next-line max-len
																								stripLeading: `${routePaths.benefitProviders.edit.products.edit.templates.edit.legacy.root}/`,
																							},
																						),
																					},
																					{
																						lazy: () =>
																							import(
																								'pages/benefit-providers/benefit-products/product-templates/layouts'
																							).then((module) => ({
																								Component: module.BenefitProductProductTemplatesLayout,
																							})),
																						path: routePaths.benefitProviders.edit.products.edit.templates.edit.modular
																							.root,
																						handle: {
																							crumb: (data: UIMatch<any, any>) => (
																								<DynamicBreadcrumb portalId={data.params.productTemplateId ?? ''} />
																							),
																						},
																						children: prepareRoutes(
																							[
																								{
																									path: '*',
																									element: <Navigate to={'details'} replace />,
																								},
																								{
																									path: routePaths.benefitProviders.edit.products.edit.templates.edit
																										.modular.details.path,
																									lazy: () =>
																										import('pages/edit-product-template').then((module) => ({
																											Component: module.EditProductTemplate,
																										})),
																									handle: {
																										crumb: () => <div>Details</div>,
																									},
																								},
																								{
																									path: routePaths.benefitProviders.edit.products.edit.templates.edit
																										.modular.marketingContents.path,
																									lazy: () =>
																										import('pages/edit-product-template').then((module) => ({
																											Component: module.MarketingContents,
																										})),
																									handle: {
																										crumb: () => <div>Marketing Contents</div>,
																									},
																								},
																							],
																							{
																								// eslint-disable-next-line max-len
																								stripLeading: `${routePaths.benefitProviders.edit.products.edit.templates.edit.modular.root}/`,
																							},
																						),
																					},
																				],
																				{
																					// eslint-disable-next-line max-len
																					stripLeading: `${routePaths.benefitProviders.edit.products.edit.templates.path}/`,
																				},
																			),
																		],
																	},
																],
																{
																	stripLeading: `${routePaths.benefitProviders.edit.products.edit.root}/`,
																},
															),
														],
													},
												],
												{
													stripLeading: `${routePaths.benefitProviders.edit.products.path}/`,
												},
											),
										],
									},
								],
								{
									stripLeading: `${routePaths.benefitProviders.edit.root}/`,
								},
							),
						},
					],
					{
						stripLeading: `${routePaths.benefitProviders.path}/`,
					},
				),
			],
		},
	]
}

const RootPath = () => {
	const { id } = useParams<{ id: string }>()

	return <Navigate to={generatePath(routePaths.benefitProviders.edit.details.path, { id: id! })} replace />
}
