export const API_NAME = 'benefitelections'

export const endpoints = {
	BenefitPlans: getBenefitPlansEndpoints(),
	BenefitProducts: getBenefitProductsEndpoints(),
	CorestreamProducts: getCorestreamProductsEndpoints(),
	Rates: getRatesEndpoints(),
	ProductTemplates: getProductTemplatesEndpoints(),
	Tokens: getTokensEndpoints(),
}

function getBenefitPlansEndpoints() {
	const bpEndpoints = {
		all: 'BenefitPlans' as const,
		editRates: (beneiftPlanId: string, rateInfoId: string) => `${bpEndpoints.all}/${beneiftPlanId}/Rates/${rateInfoId}`,
		getById: (benefitPlanId: string) => `${bpEndpoints.all}/${benefitPlanId}`,
		getLatestRate: (benefitPlanId: string) => `${bpEndpoints.all}/${benefitPlanId}/Rates/Latest`,
		getRates: (benefitPlanId: string, rateInfoId: string) => `${bpEndpoints.all}/${benefitPlanId}/Rates/${rateInfoId}`,
	}

	return bpEndpoints
}

function getBenefitProductsEndpoints() {
	const bpEndpoints = {
		all: 'BenefitProducts' as const,
		getById: (benefitProductId: string) => `${bpEndpoints.all}/${benefitProductId}`,
		getRatingTypes: () => `${bpEndpoints.all}/RatingTypes`,
		getBenefitProductPlans: (benefitProductId: string) => `${bpEndpoints.all}/${benefitProductId}/BenefitPlans`,
	}

	return bpEndpoints
}

function getCorestreamProductsEndpoints() {
	const cpEndpoints = {
		all: 'CorestreamProducts' as const,
		getById: (corestreamProductId: string) => `${cpEndpoints.all}/${corestreamProductId}`,
		edit: (corestreamProductId: string) => `${cpEndpoints.all}/${corestreamProductId}`,
	}

	return cpEndpoints
}

function getRatesEndpoints() {
	const ratesEndpoints = {
		all: 'Rates' as const,
		getRatesStrategies: () => `${ratesEndpoints.all}/RatesStrategies`,
	}

	return ratesEndpoints
}

function getProductTemplatesEndpoints() {
	const ptEndpoints = {
		all: 'ProductTemplates' as const,
		getById: (productTemplateId: string) => `${ptEndpoints.all}/${productTemplateId}`,
		modular: {
			all: 'ProductTemplates/Modular' as const,
			getById: (productTemplateId: string) => `${ptEndpoints.modular.all}/${productTemplateId}`,
			edit: (productTemplateId: string) => `${ptEndpoints.modular.all}/${productTemplateId}`,
			create: () => `${ptEndpoints.modular.all}`,
		},
		templateLayout: {
			getById: (templateLayoutId: string) => `ProductTemplates/TemplateLayout/${templateLayoutId}`,
		},
	}

	return ptEndpoints
}

function getTokensEndpoints() {
	const tokensEndpoints = {
		all: 'Tokens' as const,
		editCorestreamProductTokens: (corestreamProductId: string) =>
			`${tokensEndpoints.all}/CorestreamProduct/${corestreamProductId}`,
	}

	return tokensEndpoints
}
