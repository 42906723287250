import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ClassicEditor, EditorConfig } from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import UrlBuilder from 'services/util/url-builder'
import {
	bulletedListOnlyConfig,
	getDefaultEditorConfig,
	getFormattedTextAndBulletsConfig,
	getTextAndBulletsConfig,
} from './ckeditor-configurations'
import { CkeditorSimpleUploadConfig } from './ckeditor-configurations/types'
import { useCKeditorEntityId } from './ckeditor-entity-id-context'
import './ckeditor-input.module.css'
import { convertToUnorderedList, getCkEditorLicenseKey, handleEditorReady, removeAttributes } from './utils'

/**
 * A wrapper around the CKEditor component that provides a default configuration for the editor.
 *
 * **Image Uploading Feature**
 *
 * The image uploading feature is enabled optmistically via the ctx `CkeditorEntityIdContext`
 *  and the `imageUploadEntityId` prop as a fallback.
 * - To opt out of this behavior set the `imageUploadEnabled` prop to `false`
 *
 */
export const CkeditorInput = ({
	imageUploadEnabled,
	imageUploadEntityId,
	preset,
	dataTestId,
	disabled,
	id,
	onChange,
	initialData,
	onBlur,
	clearFormatting,
}: CkeditorProps) => {
	let config: EditorConfig | undefined
	const editorRef = useRef<any>(null)
	const [isToolbarClicked, setIsToolbarClicked] = useState(false)

	// entityId is used to identify the entity that the editor is editing.
	// This is used to upload images to the correct entity
	let entityId = useCKeditorEntityId() ?? (imageUploadEntityId ? String(imageUploadEntityId) : undefined)

	// manual opt out of image uploading
	if (!imageUploadEnabled === false) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		entityId = undefined
	}

	const uploadConfig = useCkeditorUploadConfig(entityId)
	const licenseKey = getCkEditorLicenseKey()

	switch (preset) {
		case 'default':
			config = getDefaultEditorConfig({ uploadConfig, licenseKey })
			break
		case 'bulletdListOnly':
			config = bulletedListOnlyConfig({ uploadConfig, licenseKey })
			break
		case 'emptyAndBullets':
			config = getTextAndBulletsConfig({ uploadConfig, licenseKey })
			break
		case 'formattedTextAndBulletsConfig':
			config = getFormattedTextAndBulletsConfig({ uploadConfig, licenseKey })
			break
	}

	/**
	 * Handles the onBlur call
	 * When it's a bulleted list, it will convert all content into an unordered list (as long as you aren't in a toolbar)
	 */
	const onBlurHandler = (editor) => {
		if (preset === 'bulletdListOnly' && !isToolbarClicked) {
			const editorContent = editor.getData()
			const convertedContent = convertToUnorderedList(editorContent)
			editor.setData(convertedContent)
		}

		if (onBlur) onBlur()
	}

	// Reference setter for the ckeditor field
	const setEditorRef = useCallback((ref) => {
		if (ref) {
			editorRef.current = ref
		}
	}, [])

	return (
		<CKEditor
			ref={setEditorRef}
			data-testid={dataTestId}
			id={id}
			editor={ClassicEditor}
			onReady={(editor) => handleEditorReady(editor, setIsToolbarClicked)}
			onChange={(event, editor) => {
				const data = editor.getData()
				onChange(clearFormatting ? removeAttributes(data) : data)
			}}
			config={{ ...config, initialData }}
			onBlur={(event, editor) => onBlurHandler(editor)}
			disabled={disabled}
		/>
	)
}

export const useCkeditorUploadConfig = (entityId?: string): CkeditorSimpleUploadConfig | undefined => {
	const { user } = useAuth()

	return useMemo(() => {
		if (!entityId) {
			return undefined
		}

		const urlBuilder = new UrlBuilder()

		return {
			headers: { Authorization: `Bearer ${user?.access_token}` },
			uploadUrl: urlBuilder.setEndpoint(`Assets/CKEditorUpload/${entityId}`).url(),
			withCredentials: true,
		}
	}, [entityId, user?.access_token])
}

export type CKEditorInputConfig = {
	preset?: 'default' | 'bulletdListOnly' | 'emptyAndBullets' | 'formattedTextAndBulletsConfig'
	/**
	 * if false, disables image uploading for the editor
	 */
	imageUploadEnabled?: boolean
	/**
	 * if provided will override the entityId provided via the CkeditorEntityIdContext
	 */
	imageUploadEntityId?: string | number
}

type CkeditorProps = CKEditorInputConfig & {
	initialData?: string
	disabled?: boolean
	onChange: (data: string) => void
	onBlur?: () => void
	clearFormatting?: boolean

	id: string
	dataTestId?: string
}
