import { DowncastConversionApi, Editor, EventInfo, FileRepository, Locale, Plugin } from 'ckeditor5'
import './theme/fileupload.module.css'
import { ProgressBarView } from './theme/progress-bar-view'

/**
 * The file upload progress plugin.
 * It shows a progress bar when the file is read from the disk and while the file is uploading.
 */
export class FileUploadProgress extends Plugin {
	private progressBar: ProgressBarView | null = null

	/**
	 * @inheritDoc
	 */
	static get pluginName(): string {
		return 'fileUploadProgress'
	}

	/**
	 * @inheritDoc
	 */
	init(): void {
		// Upload status change - update file's view according to that status.
		this.editor.editing.downcastDispatcher.on('attribute:uploadStatus:$text', (evt, data, conversionApi) =>
			this.uploadStatusChange(evt, data, conversionApi),
		)
	}

	/**
	 * This method is called each time the file `uploadStatus` attribute is changed.
	 */
	uploadStatusChange(
		evt: EventInfo,
		data: { item: any; attributeNewValue: string },
		conversionApi: DowncastConversionApi,
	): void {
		const editor = this.editor
		const modelItem = data.item

		const fileRepository = editor.plugins.get(FileRepository)
		const uploadId = modelItem.getAttribute('uploadId')
		const status = uploadId ? data.attributeNewValue : null

		if (!conversionApi.consumable.consume(data.item, evt.name)) {
			return
		}

		// Show progress bar on the top of the file when the file is uploading.
		if (status === 'reading') {
			const loader = fileRepository.loaders.get(uploadId)
			if (loader) {
				this.progressBar = showProgressBar(editor, loader)
			}
		}
	}
}

function createProgressBar(locale: Locale): ProgressBarView {
	return new ProgressBarView(locale)
}

function showProgressBar(editor: Editor, loader: any): ProgressBarView {
	const progressBar = createProgressBar(editor.locale)
	//@ts-expect-error stickyPanel is actually exported
	const toolbar = editor.ui.view.stickyPanel
	progressBar.render()

	toolbar.element.append(progressBar.element)

	progressBar.on('cancel', () => {
		try {
			loader.abort()
		} catch {
			// eslint-disable-next-line no-console
			console.warn('Loader already aborted.')
		}

		removeProgressBar(toolbar, progressBar)
	})

	loader.on('change:uploadedPercent', (_evt: any, _name: string, value: number) => {
		// @ts-expect-error testing
		progressBar.set('customWidth', value)
	})

	loader.on('change:uploadResponse', (_evt: any, _name: string, value: any) => {
		if (value) {
			removeProgressBar(toolbar, progressBar)
		}
	})

	return progressBar
}

function removeProgressBar(toolbar: any, progressBar: ProgressBarView): void {
	toolbar.element.removeChild(progressBar.element)
	progressBar.destroy()
}
