import { FileDialogButtonView, Plugin, icons } from 'ckeditor5'
import { CkeditorSimpleUploadConfig } from 'components/ckeditor/ckeditor-configurations/types'

export class FileUploadUI extends Plugin {
	static get pluginName() {
		return 'FileUploadUI' as const
	}
	init(): void {
		const editor = this.editor
		const t = editor.t

		editor.ui.componentFactory.add('fileUpload', (locale) => {
			const view = new FileDialogButtonView(locale)
			const command = editor.commands.get('fileUpload')

			const options = this.editor.config.get('simpleUpload') as CkeditorSimpleUploadConfig | undefined

			view.set({
				acceptedType: ((options?.fileTypes as string[]) ?? []).join(','),
				allowMultipleFiles: (options?.allowMultipleFiles as boolean) ?? false,
				label: t('Insert file'),
				icon: icons.browseFiles,
				tooltip: true,
			})

			view.on('done', (evt, files: File[]) => {
				const [file] = files

				if (file) {
					editor.execute('fileUpload', { file })
					editor.editing.view.focus()
				}
			})

			if (!!command) {
				view.bind('isEnabled').to(command)
			}

			return view
		})
	}
}
