import { Link } from '@mui/material'
import { Navigate } from 'react-router-dom'
import { routePaths } from './route-paths'
import { prepareRoutes } from './utils/prepareRoutes'

export const useClientMonitoringRoutes = () => {
	return prepareRoutes(
		[
			{
				path: routePaths.clientMonitoring.root,
				lazy: () =>
					import('pages/client-monitoring').then((module) => ({
						Component: module.ClientMonitoringPage,
					})),
				handle: {
					crumb: () => <Link href={routePaths.clientMonitoring.root}>Client Monitoring</Link>,
				},
				children: prepareRoutes(
					[
						{
							path: routePaths.clientMonitoring.viewPage.root,
							lazy: () =>
								import('pages/client-monitoring').then((module) => ({
									Component: module.ViewPage,
								})),
						},
						// Ask Collin why he had the wildcard at the top
						{
							path: '*',
							element: <Navigate to={routePaths.clientMonitoring.viewPage.root} replace />,
						},
					],
					{
						stripLeading: `${routePaths.clientMonitoring.root}/`,
					},
				),
			},
		],
		{},
	)
}
